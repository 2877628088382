<template>
  <BlockUI v-if="submitLoader" :url="url"></BlockUI>
  <div v-else-if="!submitLoader">
    <div>
      <!-- incomplete profile section -->
      <div v-if="this.$route.path.includes('preauth')">
        <!--<p class="martop10 marbottom10 h1">
          Welcome {{ firstName }} {{ lastName }},
        </p>-->
        <!--<h1>Let's build your security profile</h1>-->
        <!--<p class="para2 marbottom40">
          Your security profile is used to identify you and help you recover
          forgotten credentials when needed.
        </p>-->
        <!--<MessagesComponent></MessagesComponent>-->
        <!--<hr class="marbottom10 mt-0" />-->
        <!--<p class="marbottom40 req-msg">All fields are required</p>-->
        <!--<h3
          class="para1 marbottom40"
          style="display: inline-block; font-size: 18px"
        >
          Setup your one-time passcode delivery option(s)
        </h3>
        <p class="para2 marbottom30">
          Your email address, mobile or landline phone number can be added to
          your profile to receive one-time passcode(OTP). You must have at least
          one option set for your account.
        </p>-->

        <div class="vbg-rsp-title-container">
          <h1 class="vbg-rsp-title-h1">
            {{ $t("message.preauth_add_verification_title") }}
          </h1>
        </div>
        <p class="vbg-rsp-dec">{{ $t("message.preauth_add_verification_desc") }}</p>
        <div class="vbg-rsp-subtitle-container">
          <h2 class="vbg-rsp-title-h2">
            {{ $t("message.postauth_add_verification_title") }}
          </h2>
        </div>
        <p v-html="preauth_summary_desc" />
      </div>
      <!-- user profile section -->
      <div v-if="this.$route.path.includes('postauth')">
        <!--<p class="martop20 h1">Add one time passcode delivery option</p>-->
        <div class="vbg-rsp-title-container">
          <h1 class="vbg-rsp-title-h1">
            {{ $t("message.postauth_add_verification_title") }}
          </h1>
        </div>
        <!--<MessagesComponent></MessagesComponent>-->
        <!--<h3 class="para1 marbottom30" style="display: inline-block;font-size: 18px;">Passcode is sent to verify identity when retrieving a forgotten username or password</h3>
        <p class="para2 marbottom30">
         Your email address, mobile or landline phone number can be added to your profile to receive one-time passcode(OTP). You must have at least one option set for your account.
        </p>-->
        <p v-html="postauth_summary_desc" />
      </div>
    </div>
    <div>
      <div class="vbg-rsp-form-container">
        <ValidationObserver
          ref="observer"
          tag="form"
          v-slot="{ handleSubmit, invalid }"
        >
          <form @submit.prevent="handleSubmit(onSubmit())">
            <div class="vbg-rsp-dropdown-container1">
              <div class="vbg-rsp-dropdown-container">
                <label
                  class="vbg-ff-input-label"
                  for="otp-dropdown"
                  :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                >
                  {{ $t("message.login_otp_device_dropdown_label") }}
                </label>
                <div id="drpdn">
                  <div
                    class="row dropdown dropdown-toggle"
                    :class="{
                      open: showDropdown,
                      'vbg-rsp-disabled-select': showVerifyOTP,
                    }"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="!showVerifyOTP && toggleDeliverySelectBox()"
                    @keyup.enter="!showVerifyOTP && toggleDeliverySelectBox()"
                    tabindex="1"
                  >
                    <div class="dropDefaultLabel">
                      <a id="dropdownMenu1" name="dropdownMenu1" aria-haspopup="true" role="button">
                        <span
                          class="padleft10"
                          :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                        >
                          {{ deliveryMethodSelectedText }}
                        </span>
                      </a>
                    </div>
                    <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
                    <ul
                      class="dropdown-menu col-xs-12 px-0"
                      aria-labelledby="dropdownMenu1"
                    >
                      <li
                        v-for="method in otpDeviceList.isSmbUser
                          ? deliveryMethodsForSmb
                          : deliveryMethods"
                        :key="method.id"
                        role="presentation"
                        v-bind:class="{
                          chosen: method.value === deliveryMethodSelected,
                        }"
                      >
                        <a
                          role="menuitem"
                          style="height: 40px"
                          @click="selectDeliveryMethod(method)"
                        >
                          <span>
                            <span>{{ method.text }}</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <span
                  id="currentPassword-help-block"
                  class="help-block para2 my-6"
                  >{{ selectboxError }}</span
                >
              </div>
            </div>
            <!-- removed coz not needed according to new post designs -->
            <!--<div
              class="row form-group no-gutters oauth-w100"
              style="width: 300px"
              v-if="
                deliveryMethodSelected == 'text' ||
                deliveryMethodSelected == 'call'
              "
            >
              <div class="col-12">
                <label class="labeltiny marbottom7" for="countryCode"
                  >Country code</label
                >
                <div
                  class="row dropdown dropdown-toggle marbottom30"
                  id="drpdn2"
                  v-bind:class="[
                    { open: showCountryDropdown },
                    showVerifyOTP ? 'field-disabled' : '',
                  ]"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="toggleCountrySelectBox()"
                >
                  <div class="col-xs-11" style="padding: 3px 9px">
                    <a id="dropdownMenu1" aria-haspopup="true" role="button">
                      <span>
                        <span :class="{ colord8dada: showVerifyOTP }">{{
                          countryCodeSelectedText
                        }}</span>
                      </span>
                    </a>
                  </div>
                  <a
                    class="col-xs-1 pull-right dropdown-arrow"
                    style="padding: 0px"
                  ></a>
                  <ul
                    v-if="deliveryMethodSelected == 'text'"
                    class="dropdown-menu col-xs-12 px-0"
                    aria-labelledby="dropdownMenu1"
                  >
                    <li
                      v-for="method in countryCodes"
                      :key="method.id"
                      role="presentation"
                      v-bind:class="{
                        chosen: method.name === countryCodeSelectedText,
                      }"
                    >
                      <a
                        role="menuitem"
                        style="height: 40px"
                        @click="selectCountryCode(method)"
                      >
                        <span>
                          <span>{{ method.name }}</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <ul
                    v-if="deliveryMethodSelected == 'call'"
                    class="dropdown-menu col-xs-12 px-0"
                    aria-labelledby="dropdownMenu1"
                  >
                    <li
                      v-for="method in countryCodesForCall"
                      :key="method.id"
                      role="presentation"
                      v-bind:class="{
                        chosen: method.name === countryCodeSelectedText,
                      }"
                    >
                      <a
                        role="menuitem"
                        style="height: 40px"
                        @click="selectCountryCode(method)"
                      >
                        <span>
                          <span>{{ method.name }}</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>

                <span
                  id="currentPassword-help-block"
                  class="help-block para2 my-6"
                  >{{ selectboxCountryError }}</span
                >
              </div>
            </div>-->
            <ValidationProvider
              name="email"
              :rules="
                deliveryMethodSelected == 'email'
                  ? 'emailrequired|emailAddress'
                  : ''
              "
              v-slot="{ errors, classes }"
            >
              <div v-if="deliveryMethodSelected == 'email'">
                <div class="user-otp-input-container">
                  <label
                    class="vbg-ff-input-label"
                    for="email"
                    :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                  >
                    Email
                  </label>
                  <div class="vbg-rsp-icon-input-group">
                    <input
                      type="text"
                      class="vbg-rsp-input-field"
                      id="email"
                      name="email"
                      ref="email"
                      v-model="emailAddress"
                      maxlength="60"
                      :class="[
                        classes,
                        {
                          'vbg-rsp-disabled-input': showVerifyOTP,
                          'vbg-rsp-input-warning': errors[0] && emailAddress,
                          //'vbg-rsp-input-success': !errors[0] && emaiAddress,
                        },
                      ]"
                      :disabled="showVerifyOTP"
                    />
                    <div
                      class="vbg-rsp-warning-icon"
                      v-if="errors[0] && emailAddress"
                    >
                      <img :src="warningIcon" />
                    </div>

                    <!--<div
                      class="vbg-rsp-success-icon"
                      v-if="!errors[0] && emaiAddress"
                    >
                      <img :src="CheckIcon" />
                    </div>-->
                  </div>
                  <span
                    id="currentPassword-help-block"
                    class="vbg-rsp-warning-msg"
                    v-if="emailAddress && errors[0]"
                    v-html="errors[0]"
                  >
                    
                  </span>
                </div>
              </div>
            </ValidationProvider>
            <!--<ValidationProvider
              name="text"
              :rules="
                deliveryMethodSelected == 'text'
                  ? 'Textrequired|onlyDigits|phoneMinDigit:10|phoneMaxDigit:12'
                  : ''
              "
              v-slot="{ errors, classes }"
            >-->
            <ValidationProvider
              name="text"
              :rules="
                deliveryMethodSelected == 'text'
                  ? 'Textrequired|phoneMinDigit:12|phoneMaxDigit:12'
                  : ''
              "
              v-slot="{ errors, classes }"
            >
              <div v-if="deliveryMethodSelected == 'text'">
                <div class="user-otp-input-container">
                  <label
                    class="vbg-ff-input-label"
                    :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                    for="text"
                  >
                    10-digit phone number
                  </label>
                  <div class="vbg-rsp-icon-input-group">
                    <input
                      type="text"
                      id="text"
                      name="text"
                      v-model="text"
                      ref="text"
                      maxlength="40"
                      class="vbg-rsp-input-field"
                      :class="[
                        classes,
                        {
                          'vbg-rsp-disabled-input': showVerifyOTP,
                          'vbg-rsp-input-warning': errors[0] && text,
                          //'vbg-rsp-input-success': !errors[0] && text,
                        },
                      ]"
                      :disabled="showVerifyOTP"
                      @input="acceptMobileNumber('text')"
                    />
                    <div class="vbg-rsp-warning-icon" v-if="errors[0] && text">
                      <img :src="warningIcon" />
                    </div>
                    <!--<div
                      class="vbg-rsp-success-icon"
                      v-if="!errors[0] && text"
                    >
                      <img :src="CheckIcon" />
                    </div>-->
                  </div>
                  <span
                    id="currentPassword-help-block"
                    class="vbg-rsp-warning-msg"
                    v-if="text && errors[0]"
                  >
                    {{ errors[0] }}
                  </span>
                </div>
              </div>
            </ValidationProvider>
            <!--<ValidationProvider
              name="call"
              :rules="
                deliveryMethodSelected == 'call'
                  ? 'Textrequired|onlyDigits|phoneMinDigit:10|phoneMaxDigit:12'
                  : ''
              "
              v-slot="{ errors, classes }"
            >-->
            <ValidationProvider
              name="call"
              :rules="
                deliveryMethodSelected == 'call'
                  ? 'Textrequired|phoneMinDigit:12|phoneMaxDigit:12'
                  : ''
              "
              v-slot="{ errors, classes }"
            >
              <div v-if="deliveryMethodSelected == 'call'">
                <div class="user-otp-input-container">
                  <label
                    class="vbg-ff-input-label"
                    :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                    for="call"
                  >
                    10-digit phone number
                  </label>
                  <div class="vbg-rsp-icon-input-group">
                    <input
                      type="text"
                      id="call"
                      name="call"
                      v-model="call"
                      ref="call"
                      maxlength="40"
                      class="vbg-rsp-input-field"
                      :class="[
                        classes,
                        {
                          'vbg-rsp-disabled-input': showVerifyOTP,
                          'vbg-rsp-input-warning': errors[0] && call,
                          //'vbg-rsp-input-success': !errors[0] && call,
                        },
                      ]"
                      :disabled="showVerifyOTP"
                      @input="acceptMobileNumber('call')"
                    />
                    <div class="vbg-rsp-warning-icon" v-if="errors[0] && call">
                      <img :src="warningIcon" />
                    </div>
                    <!--<div
                      class="vbg-rsp-success-icon"
                      v-if="!errors[0] && call"
                    >
                      <img :src="CheckIcon" />
                    </div>-->
                  </div>
                  <span
                    id="currentPassword-help-block"
                    class="vbg-rsp-warning-msg"
                    v-if="call && errors[0]"
                    >{{ errors[0] }}</span
                  >
                </div>
              </div>
            </ValidationProvider>
            <!--<p
              class="para2 marbottom17"
              v-if="
                deliveryMethodSelected == 'text' ||
                deliveryMethodSelected == 'call'
              "
            >
              Text message rates will not apply if Verizon is your service
              provider. Standard text message rates may apply otherwise.
            </p>-->
            <div class="vbg-sp-btn-continue">
              <!--<button
                type="submit"
                class="btn vbg-ff-btn-continue vbg-ff-btn-width140 vbg-rsp-add-btn"
                :class="{
                  'vbg-rsp-disabled-btn':
                    invalid ||
                    (deliveryMethodSelected != 'email'
                      ? countryCodeSelected == 'Select'
                      : '') ||
                    deliveryMethodSelected == 'Select' ||
                    showVerifyOTP,
                }"
                :disabled="
                  invalid ||
                  (deliveryMethodSelected != 'email'
                    ? countryCodeSelected == 'Select'
                    : '') ||
                  deliveryMethodSelected == 'Select' ||
                  showVerifyOTP
                "
              >
                <span>Add delivery method</span>
              </button>-->
              <button
                type="submit"
                class="btn vbg-ff-btn-continue vbg-ff-btn-width140 vbg-rsp-add-btn spacing05"
                :class="{
                  'vbg-rsp-disabled-btn':
                    invalid ||
                    deliveryMethodSelected == 'Select a delivery method' ||
                    showVerifyOTP,
                }"
                :disabled="
                  invalid || deliveryMethodSelected == 'Select a delivery method' || showVerifyOTP
                "
                id="adddeliverymethod_button"
                name="adddeliverymethod_button"
              >
                Add delivery method
              </button>
              <button
                v-if="otpDeviceList.otpDeviceList.length >= 1"
                :class="{ 'vbg-rsp-disabled-btn-cancel': showVerifyOTP }"
                type="button"
                :disabled="showVerifyOTP || smbEmptyCheck"
                class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 vbg-rsp-add-btn spacing05"
                @click="gotoOverview()"
                id="btn_back"
                name="btn_back"
              >
                Cancel
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-if="showVerifyOTP" class="vbg-rsp-verify-method-container">
      <ValidationObserver
        ref="observer1"
        tag="form"
        v-slot="{ handleSubmit, invalid }"
      >
        <form @submit.stop.prevent="handleSubmit(OnOtpSubmit())">
          <h2 class="vbg-rsp-title-h2">Verify your delivery method</h2>
          <p style="color: #000">
            Please enter the one-time passcode you received at
            {{ maskedValue }}. <br />Passcode is valid for 10 minutes.
          </p>
          <ValidationProvider
            name="onepasscode"
            rules="passCode|passCodeMindigits:6|passCodeMaxdigits:6"
            v-slot="{ errors, classes }"
          >
            <div class="user-otp-input-container">
              <label class="vbg-ff-input-label" for="onepasscode">
                Enter one-time passcode
              </label>
              <input
                type="password"
                class="vbg-rsp-input-field"
                id="onepasscode"
                name="onepasscode"
                v-model="onepasscode"
                maxlength="6"
                :class="classes"
                style="display: block"
              />
              <span
                id="currentPassword-help-block"
                class="vbg-rsp-warning-msg"
                v-if="onepasscode && errors[0]"
              >
                {{ errors[0] }}
              </span>
            </div>
          </ValidationProvider>
          <div class="vbg-sp-btn-continue">
            <button
              type="submit"
              class="btn vbg-ff-btn-continue vbg-rsp-btn-width120 btn-verify spacing05"
              :class="{ 'vbg-ff-disabled': !onepasscode || invalid }"
              :disabled="!onepasscode || invalid"
              id="verify_button"
              name="verify_button"
            >
              Verify
            </button>
            <button
              type="button"
              class="btn vbg-ff-btn-cancel vbg-rsp-btn-width120 btn-verify spacing05"
              @click="cancelVerifyOtp()"
              id="cancel_button"
              name="cancel_button"
            >
              Cancel
            </button>
          </div>

          <p class="vz-text-link resend-passcode-link">
            <a
              class="vz-text-link vbg-rsp-link"
              href="#"
              id="resendlink"
              @click="onSubmit()"
            >
              Resend passcode
            </a>
          </p>
          <p class="vz-text-link cant-receive-passcode-link">
            <a
              class="vz-text-link vbg-rsp-link"
              href="#"
              @click="openModel()"
              id="launchInfo"
            >
              I can't receive a one-time passcode
            </a>
          </p>
          <!-- popup for cannot receive one time passcode -->
          <!--<div class="row">
            <div id="modal-popup" class="modal fade">
              <div
                class="modal-dialog modal-dialog-centered modelCantRecOtp"
                role="document"
              >
                <div class="modal-content">
                  <h3 class="mt-0">Can't receive a one-time passcode?</h3>
                  <a
                    data-dismiss="modal"
                    class="col-xs-1 pull-right close-icon-popup"
                    style="padding: 0px; cursor: pointer"
                  ></a>
                  <p class="para3 marbottom10">
                    Please contact one of the following for further assistance:
                  </p>
                  <ul style="margin: 1rem">
                    <li>Company's Administrator</li>
                    <li>Verizon Customer Service at 1-800-922-0204</li>
                    <li>
                      WITS/ Service@once users only: 800 381 3444 option 6
                    </li>
                    <li>
                      US and Canada Wireline Customers only: 800.569.8799 (M-F
                      9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET)
                    </li>
                    <li>
                      EMEA, APAC, LATAM Wireline Customers only:
                      00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com
                    </li>
                  </ul>
                  <div class="modal-footer">
                    <div class="col-sm-2 text-left">
                      <button
                        type="submit"
                        data-dismiss="modal"
                        class="btn primary_black marright20 oauth-flow-w-100"
                      >
                        <span>Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div id="modal-popup" class="modal fade">
            <div class="vbg-model-container" role="document">
              <div class="modal-content modal-content-container">
                <div class="modal-container">
                  <div class="close-icon-container" tabindex="1">
                    <a data-dismiss="modal">
                      <img
                        src="@/common/assets/styles/corp/images/close_black.png"
                        style="height: 20px; width: 20px"
                      />
                    </a>
                  </div>
                  <div class="window-content mar-bottom50">
                    <div>
                      <h2 class="martop0 marbottom20 modal-content-title">
                        Can't receive a one time passcode?
                      </h2>
                      <p class="modal-content-description">
                        Please try another delivery method, contact your company
                        administrator, or
                        <a
                          href="https://www.verizon.com/business/support/"
                          class="vbg-ff-focus vbg-support-link"
                          target="_blank"
                          tabindex="2"
                          id="visit_support"
                          name="visit_support"
                          >visit our support page.</a
                        >
                      </p>
                    </div>
                  </div>

                  <div class="modal-btn-container martop100">
                    <button
                      type="button"
                      data-dismiss="modal"
                      class="btn vbg-ff-focus-btn primary_black martop50 vbg-rel-bottom-40 bttn-container  spacing05"
                      tabindex="3"
                      id="close_modal"
                      name="close_modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import MessagesComponent from "@/profile/components/core/MessagesComponent";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import downCaret from "@/common/assets/styles/corp/images/down-caret_blk.png";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import CheckIcon from "@/common/assets/styles/corp/images/checkmark.png";
import translations from "@/common/assets/json/messages1.json";

export default {
  name: "AddOTPDeviceComponent",
  data() {
    return {
      CheckIcon,
      warningIcon,
      downCaret,
      focused: false,
      //url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
      //url : loadingImage,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      disable: false,
      disableb2: false,
      call: "",
      text: "",
      emailAddress: "",
      deliveryMethodSelected: "Select a delivery method",
      countryCodeSelected: "Select",
      deliveryMethodSelectedText: "Select a delivery method",
      countryCodeSelectedText: "Select",
      onepasscode: "",
      deliveryMethods: [
        { value: "email", text: "Email" },
        { value: "text", text: "Text (US only)" },
        { value: "call", text: "Call (US and Canada)" },
      ],
      deliveryMethodsForSmb: [
        { value: "text", text: "Text me" },
        { value: "call", text: "Call me (US and Canada only)" },
      ],
      countryCodesForCall: [
        { value: "1", name: "United States +1" },
        { value: "1", name: "Canada +1" },
      ],

      showDropdown: false,
      showCountryDropdown: false,
      selectedMethod: { value: "Select a delivery method", text: "Select a delivery method" },
      selectboxError: "",
      selectboxCountryError: "",
      postauth_summary_desc: translations.en.message['postauth_add_verification_desc'],
      preauth_summary_desc: translations.en.message['preauth_add_verification_subdesc'],
    };
  },
  components: {
    MessagesComponent,
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Profile:Add OTP device");
  },
  methods: {
    ...mapActions("profile", [
      "sendotpToDevice",
      "verifyOtpCode",
      "addOTPDevice",
      "getUserProfile",
      "isTxnValid",
      "getOtpDeviceList",
      "getCountryCodes",
      "resetMessages",
      "resetShowVerifyOtp",
      "updateTargetUrl",
    ]),

    openModel() {
      $("#modal-popup").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    toggleDeliverySelectBox() {
      if (!this.showVerifyOTP) {
        this.showDropdown = !this.showDropdown;
        //if (!this.showDropdown && this.deliveryMethodSelected == "Select") {
        //  this.selectboxError = "Please select a delivery method.";
        //} else {
        //  this.selectboxError = "";
        //}
      }
    },
    toggleCountrySelectBox() {
      if (!this.showVerifyOTP) {
        this.showCountryDropdown = !this.showCountryDropdown;
        if (!this.showCountryDropdown && this.countryCodeSelected == "Select") {
          this.selectboxCountryError = "Please select a country code.";
        } else {
          this.selectboxCountryError = "";
        }
      }
    },
    cancelVerifyOtp() {
      this.resetShowVerifyOtp(false);
      this.resetMessages();
      // this.$store.commit("profile/setShowVerifyOTP", false);
    },
    selectDeliveryMethod(method) {
      requestAnimationFrame(() => this.$refs.observer.reset());
      this.call = "";
      this.text = "";
      this.emailAddress = "";
      this.countryCodeSelected = "Select";
      this.countryCodeSelectedText = "Select";
      this.deliveryMethodSelected = method.value;
      this.deliveryMethodSelectedText = method.text;
      if (this.deliveryMethodSelected == "email") {
        this.$nextTick(() => $("#email").focus());
      }
      if (this.deliveryMethodSelected == "text") {
        this.$nextTick(() => $("#text").focus());
      }
      if (this.deliveryMethodSelected == "call") {
        this.$nextTick(() => $("#call").focus());
      }
    },
    selectCountryCode(code) {
      this.countryCodeSelected = code.value;
      this.countryCodeSelectedText = code.name;
    },

    async onSubmit() {
      await this.$refs.observer.validate().then((success) => {
        if (!success) {
          this.disable = false;
          this.disableb2 = true;
          return;
        } else {
          this.disable = true;
          this.disableb2 = false;
          if (this.deliveryMethodSelected == "email") {
            let payload = {
              uuID: this.uuID,
              emailAddress: this.emailAddress,
              otpType: "email",
            };
            this.sendotpToDevice(payload);
          } else if (this.deliveryMethodSelected == "call") {
            let payload = {
              uuID: this.uuID,
              mtn: this.call.replace(/-/g, ""),
              countryCode: "1",
              otpType: "ivr",
            };
            this.sendotpToDevice(payload);
          } else if (this.deliveryMethodSelected == "text") {
            let payload = {
              uuID: this.uuID,
              mtn: this.text.replace(/-/g, ""),
              countryCode: "1",
              otpType: "sms",
            };
            this.sendotpToDevice(payload);
          }
        }
      });
      this.cancel1();
    },

    async OnOtpSubmit() {
      await this.$refs.observer1.validate().then((success) => {
        if (!success) {
          return;
        } else {
          if (this.deliveryMethodSelected == "call") {
            let payload = {
              uuID: this.uuID,
              otpToken: this.onepasscode,
              deliveryMethodSelected: "ivr",
              emailAddress: this.emailAddress,
              mtn: this.call.replace(/-/g, ""),
              countryCode: "1",
            };
            this.verifyOtpCode(payload);
          } else if (this.deliveryMethodSelected == "text") {
            let payload = {
              uuID: this.uuID,
              otpToken: this.onepasscode,
              deliveryMethodSelected: "sms",
              emailAddress: this.emailAddress,
              mtn: this.text.replace(/-/g, ""),
              countryCode: "1",
            };
            this.verifyOtpCode(payload);
          } else if (this.deliveryMethodSelected == "email") {
            let payload = {
              uuID: this.uuID,
              otpToken: this.onepasscode,
              deliveryMethodSelected: "email",
              emailAddress: this.emailAddress,
              mtn: this.text.replace(/-/g, ""),
              countryCode: "",
            };
            this.verifyOtpCode(payload);
          }
        }
      });
      this.cancel1();
    },
    cancel() {
      (this.disable = false),
        (this.disableb2 = true),
        this.$nextTick(() => this.$refs.observer.reset());
      (this.onepasscode = ""),
        this.$nextTick(() => this.$refs.observer1.reset());
    },
    cancel1() {
      (this.onepasscode = ""),
        this.$nextTick(() => this.$refs.observer1.reset());
    },
    gotoOverview() {
      this.resetMessages();
      if (this.currentRouteName.includes("/profile/postauth/addotpdevice")) {
        this.$router.push({
          path: "overview",
          query: { target: this.logOutGotoUrl },
        });
      } else {
        this.$router.push({ path: "otpsummary" });
      }
    },
    acceptMobileNumber(payload) {
      let x = this[payload]
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let phoneNum = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

      this[payload] = phoneNum;
    },
  },
  computed: {
    ...mapGetters("profile", [
      "showVerifyOTP",
      "gotoDevSumry",
      "uuID",
      "deliveryMethodSelected",
      "emailAddress",
      "errorMsg",
      "showerrorMsg",
      "infoMsg",
      "showinfoMsg",
      "submitLoader",
      "otpDeviceList",
      "countryCodes",
      "changeURL",
      "maskedValue",
      "firstName",
      "lastName",
      "stepupVerified",
      "smbAddDeviceMsg",
      "smbEmptyCheck",
      "logOutGotoUrl",
    ]),
    deliveryMethodSelected: {
      get: function () {
        return this.$store.getters["profile/deliveryMethodSelected"];
      },
      set: function (newVal) {
        this.$store.dispatch("profile/setDeliveryMethodSelected", newVal);
      },
    },
    emailAddress: {
      get: function () {
        return this.$store.getters["profile/emailAddress"];
      },
      set: function (newVal) {
        this.$store.dispatch("profile/setEmailAddress", newVal);
      },
    },
    currentRouteName() {
      return this.$route.path;
    },
  },
  mounted() {
    // URLSearchParams not supported in IE
    // let params = new URLSearchParams(window.location.search)
    if (this.getParameterByName("stepupVerification")) {
      this.$store.commit("profile/setStepupVerified", true);
    } else {
      this.$store.commit("profile/setStepupVerified", false);
    }
    this.$store.commit("profile/setAttributedUpdated", "addotpdevice");
    this.disableb2 = true;

    if (this.currentRouteName.includes("/profile/postauth/addotpdevice")) {
      this.getUserProfile(this.getParameterByName("target"));
      if (iamvar_oneSessionPerLoginEnable !== true) {
        this.isTxnValid(this.getParameterByName("target"));
      }
    } else {
      this.getOtpDeviceList(this.uuID);
    }
    this.updateTargetUrl(this.getParameterByName("target"));
    //this.getCountryCodes();
    $(document).ready(function () {
      $("#Info").popover({ trigger: "hover focus" });
    });
    /* Event Listener For Closing Select Box */
    document.addEventListener("click", (e) => {
      if (this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false;
      }
    });
    //document.addEventListener("click", (e) => {
    //  if (e.target.parentElement.id != "drpdn" && this.showDropdown === true) {
    //    this.showDropdown = false;
    //  }
    //  if (
    //    e.target.parentElement.id != "drpdn2" &&
    //    this.showCountryDropdown === true
    //  ) {
    //    this.showCountryDropdown = false;
    //  }
    //});
    if(this.$route.path.includes('postauth')) {
      this.changeContextBiocatch('postauth_addotpdevice_page')
    } else {
      this.changeContextBiocatch('preauth_addotpdevice_page')
    }
    sessionStorage.removeItem('optSelected')
  },
  watch: {
    gotoDevSumry: function (newVal) {
      if (newVal === "preauth") {
        this.$router.push({ path: "otpsummary" });
      } else {
        this.$router.push({
          path: "overview",
          query: { target: this.logOutGotoUrl },
        });
      }
    },
    $route(to, from) {
      this.resetMessages();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box !important;
}
.resend-passcode-link {
  margin-top: 16px !important;
}
.cant-receive-passcode-link {
  margin-top: 24px !important;
}
label {
  display: block !important;
}
.dropDefaultLabel {
  padding-right: 0px;
  padding-left: 0;
  flex: 1;
}
.row.dropdown.dropdown-toggle {
  align-items: center;
}
.dropdown-menu > li > a {
  color: #000 !important;
}
.dropdown {
  display: flex;
  position: relative;
  height: 44px !important;
  font-size: 16px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: none !important;
  display: none !important;
}

.open > .dropdown-menu {
  display: block !important;
}
.user-otp-input-container {
  margin-top: 32px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: grey;
}
.dropdown-menu > li > a {
  display: block !important;
}
.verify-heading {
  margin: 0 0 20px 0;
  height: 31px;
  color: #000;
  font-family: NeueHaasGroteskDisplay !important;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30.4px;
  font-weight: 700 !important;
}

#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}
.vz-text-link {
  color: #000;
  margin-bottom: 10px !important;
  text-decoration: none !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.requiredtext {
  position: absolute;
  right: 0;
}
li {
  list-style-type: disc;
}
.modal-footer {
  padding: 20px 0 0;
  border-top: 0 none;
}
.vbg-rsp-add-btn {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.vbg-rsp-input-field {
  margin-bottom: 8px !important;
  width: 384px !important;
  border-radius: 4px !important
}
.btn-verify {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.vbg-rsp-title-h2 {
  color: #000 !important;
} 
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}
.vbg-rel-bottom-40 {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-rsp-title-h1 {
  line-height: 36px;
  font-weight: 700;
}
.vbg-rsp-dec {
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  line-height: 20px;
}
.vbg-rsp-dropdown-container {
  max-width: 384px !important;
}
.vbg-sp-btn-continue {
  margin-top: 32px;
}
@media (min-width: 350px) and (max-width: 550px) {
  .setup-secure-profile {
    /*width: 95% !important;*/
  }
  .sendOtpBtn {
    padding: 12px 25px !important;
  }
  .modelCantRecOtp {
    width: 95% !important;
    height: 575px;
    margin-left: 5px;
  }
  .modal-content {
    text-align: justify;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .setup-secure-profile {
    /*width: 88% !important; */
  }
  .modal-content {
    text-align: justify;
  }
}

@media (max-width: 1024px) {
  .setup-secure-profile {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .marbottom40 {
    margin-bottom: 20px !important;
  }
  .oauth-flow-w-100 {
    width: 100% !important;
  }
  .oauth-flow-ml0 {
    margin-left: 0 !important;
  }
  .sendOtpBtn {
    margin-bottom: 10px !important;
  }
  .oauth-w100 {
    width: 100% !important;
  }
  .dropdown-menu li.chosen > a {
    color: #000 !important;
  }
  .dropdown-menu li.chosen {
    border-left: none !important;
  }
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    letter-spacing: 0;
    line-height: 16px;
    font-size: 16px;
  }
  .resend-passcode-link {
    margin-top: 24px !important;
  }
  .vz-text-link {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .tablet-c-button {
    width: 47% !important;
    margin-right: 0 !important;
    margin-bottom: 50px !important;
  }
  .oauth-flow-ml0 {
    float: right;
  }
}
/* CSS only for desktop */
@media (min-width: 1025px) {
  .vbg-rsp-add-btn {
    width: 206px !important;
  }
  .btn-verify {
    width: 120px;
  }
}

/* modal css */
.modal-button {
  margin-top: 37.34px;
  height: 44px;
  width: 188px;
}
.modal-content-container {
  position: relative;
  width: 640px;
  height: 431px !important;
  margin: 0 auto;
  padding: 48px !important;
}
.modal-content-title {
  height: auto;
  width: 100%;
  font-size: 32px;
  line-height: 32px;
  margin-top: 0px !important;
  text-align: left !important;
}
.modal-content-description {
  margin-top: 24px;
  height: 40px;
  width: 100%;
  font-size: 16px !important;
  letter-spacing: 0.5px !important;
  line-height: 20px;
  text-align: left !important;
}
.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px;
}
.close-icon-container:focus {
  outline: 1px dashed #000;
  outline-offset: -12;
}
.bttn-container {
  height: 44px;
  width: 120px;
  border-radius: 22px;
  background-color: #000000;
  font-size: 16px !important;
  line-height: 18px !important ;
  padding: 12px !important;
  text-align: center;
}
.modal-open {
  padding-right: unset !important;
}
.window-content {
  height: 96px;
  width: 90%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .modal-content {
    height: auto;
  }
}

@media (max-width: 640px) {
  .modal-content-container {
    width: 100%;
    height: 100% !important;
    padding: 0px !important;
  }
  .modal-container {
    height: 360px;
    padding: 69px 0px 0px 16px;
  }
  .modal-content-title {
    height: 58px !important;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
  }
  .modal-content-description {
    margin-top: 24px;
  }
  .modal-loader {
    margin-top: 62px;
  }
  .modal-btn-container {
    padding-right: 16px;
  }
  .close-icon-container {
    height: 48px;
    width: 48px;
    position: relative;
    float: right;
  }
  .close-icon-container {
    top: -12px;
  }
  .bttn-container {
    width: 100%;
  }
  .window-content {
    height: 142px;
    width: 90% !important;
  }
}
.vbg-support-link {
  text-decoration: none !important;
  cursor: pointer !important;
  color: #000 !important;
  border-bottom: 1px solid #000;
}
.vbg-model-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.vbg-ff-focus:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 1px !important;
}
.vbg-ff-focus-btn:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 2px !important;
}
@media (max-width: 280px) {
  .modal-content-description {
    max-width: 260px;
  }
  .modal-content-title {
    height: auto !important;
  }
  .mar-bottom50 {
    margin-bottom: 60px;
  }
}
/* button align on popup desktop/tablet */
@media (min-width: 641px) {
  .vbg-rel-bottom-40 {
    position: absolute;
    bottom: 40px !important;
  }
}
</style>
