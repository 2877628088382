<template>
  <BlockUI v-if="submitLoader" :url="url"></BlockUI>
  <div v-else-if="!submitLoader">
    <div class="vbg-rsp-title-container">
      <h1 class="vbg-rsp-title-h1">
        {{ $t("message.postauth_add_verification_title") }}
      </h1>
    </div>
    <p v-html="postauth_summary_desc" />
    <div class="vbg-rsp-form-container">
      <form @submit.prevent="onSubmit()">
        <div class="vbg-rsp-dropdown-container1">
          <div class="vbg-rsp-dropdown-container">
            <label class="vbg-ff-input-label" for="otp-dropdown" :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }">
              {{ $t("message.login_otp_device_dropdown_label") }}
            </label>
            <div id="drpdn">
              <div class="row dropdown dropdown-toggle" :class="{
                open: showDropdown,
                'vbg-rsp-disabled-select': showVerifyOTP,
              }" aria-haspopup="true" aria-expanded="false" @click="!showVerifyOTP && toggleDeliverySelectBox()"
                @keyup.enter="!showVerifyOTP && toggleDeliverySelectBox()" tabindex="1">
                <div class="dropDefaultLabel">
                  <a id="dropdownMenu1" name="dropdownMenu1" aria-haspopup="true" role="button">
                    <span class="padleft10" :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }">
                      {{ selectedOtpMethod.label }}
                    </span>
                  </a>
                </div>
                <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
                <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1">
                  <li v-for="(method, index) in otpDeviceList.isSmbUser
                    ? otpMethodsForSmb
                    : otpMethods" :key="method.id" role="presentation" v-bind:class="{
                        chosen: method.id === selectedOtpMethod.id,
                      }">
                    <a role="menuitem" style="height: 40px" @keyup.enter="selectDeliveryMethod(method, 'enter')"
                      @keyup="deviceListkeyupHandler($event, index)" @click="selectDeliveryMethod(method, 'click')"
                      ref="selectDeviceList">
                      <span>
                        <span>{{ method.label }}</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedOtpType">
          <div class="user-otp-input-container">
            <label class="vbg-ff-input-label" for="email">
              {{
                selectedOtpType === "email" ? "Email" : "10-digit phone number"
              }}
            </label>
            <div class="vbg-rsp-icon-input-group">
              <input type="text" class="vbg-rsp-input-field" id="user-input-val" name="user-input-val"
                ref="userInputText" @keydown.space.prevent v-model="userInputText" @input="validateUserInput()"
                maxlength="60" :class="[
                  {
                    'vbg-rsp-disabled-input': showVerifyOTP,
                    'vbg-rsp-input-warning': !isValidText && userInputText,
                  },
                ]" :disabled="showVerifyOTP" />
              <div class="vbg-rsp-warning-icon" v-if="!isValidText && userInputText">
                <img :src="warningIcon" />
              </div>
            </div>
            <span id="currentPassword-help-block" class="vbg-rsp-warning-msg"
              v-if="selectedOtpType && userInputText && !isValidText" v-html="getWarningMessage">

            </span>
          </div>
        </div>
        <div class="vbg-sp-btn-continue">
          <button type="submit" class="btn vbg-ff-btn-continue vbg-ff-btn-width140 vbg-rsp-add-btn spacing05" :class="{
            'vbg-rsp-disabled-btn':
              disableDeliveryBtn ||
              showVerifyOTP,
          }" :disabled="disableDeliveryBtn || showVerifyOTP
              " id="adddeliverymethod_button" name="adddeliverymethod_button">
            Add delivery method
          </button>
          <button v-if="otpDeviceList.otpDeviceList.length >= 1"
            :class="{ 'vbg-rsp-disabled-btn-cancel': showVerifyOTP }" type="button"
            :disabled="showVerifyOTP || smbEmptyCheck"
            class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 vbg-rsp-add-btn spacing05" @click="gotoOverview()"
            id="btn_back" name="btn_back">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import $ from "jquery";
import { setPageDetails, eventTracking, errorSet } from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import downCaret from "@/common/assets/styles/corp/images/down-caret_blk.png";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import CheckIcon from "@/common/assets/styles/corp/images/checkmark.png";
import translations from "@/common/assets/json/messages1.json";

export default {
  name: "AddDeliveryComponent",
  data() {
    return {
      CheckIcon,
      warningIcon,
      downCaret,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      selectedOtpMethod: {
        id: "select-label",
        label: "Select a delivery method",
        text: "Select",
      },
      otpMethods: [
        { id: "email", label: "Email" },
        { id: "text", label: "Text (US only)" },
        { id: "call", label: "Call (US and Canada)" },
      ],
      otpMethodsForSmb: [
        { id: "text", label: "Text me" },
        { id: "call", label: "Call me (US and Canada)" },
      ],
      showDropdown: false,
      postauth_summary_desc: translations.en.message['postauth_add_verification_desc'],
      preauth_summary_desc: translations.en.message['preauth_add_verification_subdesc'],
      selectedOtpType: "",
      userInputText: "",
      emailFormatRegex:
        /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
      isValidText: false,
    };
  },

  mixins: [commonMixin],
  async created() {
    setPageDetails("Profile:Add OTP device");
  },
  methods: {
    ...mapMutations('profile', ['setPath', 'setSelectedOtpType', 'setUserInputText']),
    ...mapActions("profile", [
      "sendotpToDevice",
      "addOTPDevice",
      "getUserProfile",
      "isTxnValid",
      "getOtpDeviceList",
      "resetMessages",
      "updateTargetUrl",
    ]),


    toggleDeliverySelectBox() {
      if (!this.showVerifyOTP) {
        this.showDropdown = !this.showDropdown;
      }
    },
    selectDeliveryMethod(selDevice) {
      this.selectedOtpMethod = selDevice;
      this.selectedOtpType = selDevice.id;
      this.userInputText = "";
      this.isValidText = false;
      this.$nextTick(() => this.$refs.userInputText.focus());
    },
    async onSubmit() {
      try {
        const payload = {
          uuID: this.uuID,
        };
        if (this.selectedOtpType == "email") {
          payload.emailAddress = this.userInputText;
          payload.otpType = "email";
        } else if (this.selectedOtpType == "call") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "ivr";
        } else if (this.selectedOtpType == "text") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "sms";
        }

        // submit addotpdevice request
        await this.sendotpToDevice(payload);
      } catch (error) {
      }
    },
    gotoOverview() {
      this.resetMessages();
      if ( this.userType == "VZW" && this.deviceType != "/mobile") {
        this.$router.push({
          path: "deliveryoverview",
          query: { target: this.logOutGotoUrl ?? undefined },
        });
      } else {
        this.$router.push({
          path: "overview",
          query: { target: this.logOutGotoUrl ?? undefined },
        });
      }
    },
    acceptMobileNumber(payload) {
      let x = payload
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let phoneNum = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

      return phoneNum;
    },
    deviceListkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const deviceList = this.$refs.selectDeviceList;
      switch (e.key) {
        case "ArrowUp":
          if (index > 0) {
            deviceList[index - 1].focus();
          }
          if (index <= 0) {
            deviceList[deviceList.length - 1].focus();
          }
          break;
        case "ArrowDown":
          if (index < deviceList.length - 1) {
            deviceList[index + 1].focus();
          } else {
            deviceList[0].focus();
          }
          break;
        default:
          break;
      }
    },
    validateUserInput() {
      this.isValidText = false;
      if (this.selectedOtpType === "email") {
        if (!this.emailFormatRegex.test(this.userInputText)) {
          return;
        }
        this.isValidText = true;
      } else {
        this.userInputText = this.acceptMobileNumber(this.userInputText);
        if (this.userInputText.length === 12) {
          this.isValidText = true;
          return;
        }
      }
    },
    parseUrl() {
      const urlParams = this.getParameterByName("target");
      if(urlParams) {
        const fragment = window.location.hash.substring(1);
        const targetParam = urlParams+'#'+ fragment;
        this.updateTargetUrl(targetParam)
      } else {
        this.updateTargetUrl(null);
      }
    },
  },
  computed: {
    ...mapGetters("profile", [
      "showVerifyOTP",
      "gotoDevSumry",
      "uuID",
      "emailAddress",
      "submitLoader",
      "otpDeviceList",
      "stepupVerified",
      "smbEmptyCheck",
      "logOutGotoUrl",
      'userType',
      'deviceType'
    ]),
    currentRouteName() {
      return this.$route.path;
    },
    disableDeliveryBtn() {
      if (this.selectedOtpType && this.isValidText) {
        return false;
      }

      return true;
    },
    getWarningMessage() {
      if (this.selectedOtpType === "email") {
        return translations.en.message["reset_security_invalid_email_err"];
      } else {
        return translations.en.message["reset_security_invalid_phone_err"];
      }
    },
  },
  mounted() {
    if (this.getParameterByName("stepupVerification")) {
      this.$store.commit("profile/setStepupVerified", true);
    } else {
      this.$store.commit("profile/setStepupVerified", false);
    }
    this.$store.commit("profile/setAttributedUpdated", "addotpdevice");

    if (this.currentRouteName.includes("/profile/postauth/addotpdevice")) {
      this.getUserProfile(this.getParameterByName("target"));
      if (iamvar_oneSessionPerLoginEnable !== true) {
        this.isTxnValid(this.getParameterByName("target"));
      }
    } else {
      this.getOtpDeviceList(this.uuID);
    }
      // this.updateTargetUrl(this.getParameterByName("target"));
    /* Event Listener For Closing Select Box */
    document.addEventListener("click", (e) => {
      if (this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false;
      }
    });
    if (this.$route.path.includes('postauth')) {
      this.changeContextBiocatch('postauth_addotpdevice_page')
    }
    sessionStorage.removeItem('optSelected')
    this.setPath(this.$route.path)
  },
  watch: {
    $route(to, from) {
      this.resetMessages();
    },
    userType: function (newVal) {
      if (newVal) {
        this.parseUrl();
      }
    },
    showVerifyOTP: function (newVal) {
      if (newVal) {
          this.$router.push({
            name: "VerifyDeliveryComponent",
            query: { target: this.logOutGotoUrl ?? undefined },
          });
      }
    },
    selectedOtpType: function (newVal) {
      this.setSelectedOtpType(newVal);
    },
    userInputText: function (newVal) {
      this.setUserInputText(newVal);
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box !important;
}

label {
  display: block !important;
}

.dropDefaultLabel {
  padding-right: 0px;
  padding-left: 0;
  flex: 1;
}

.row.dropdown.dropdown-toggle {
  align-items: center;
}

.dropdown-menu>li>a {
  color: #000 !important;
}

.dropdown {
  display: flex;
  position: relative;
  height: 44px !important;
  font-size: 16px;
  border-radius: 4px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: none !important;
  display: none !important;
}

.open>.dropdown-menu {
  display: block !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: grey;
}

.dropdown-menu>li>a {
  display: block !important;
}

#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}

li {
  list-style-type: disc;
}

.vbg-rsp-add-btn {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}

.vbg-rsp-input-field {
  margin-bottom: 8px !important;
  border-radius: 4px !important
}

.btn:active,
.btn:hover,
.btn:focus {
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}

.vbg-rsp-title-h1 {
  line-height: 36px;
  font-weight: 700;
}

.vbg-rsp-dropdown-container {
  margin-bottom: 24px;
}

.vbg-sp-btn-continue {
  margin-top: 32px;
}

.vbg-rsp-warning-icon {
  right: -90px;
}

/* CSS only for desktop */
@media (min-width: 1025px) {
  .vbg-rsp-add-btn {
    width: 206px !important;
  }

  .vbg-rsp-input-field {
    width: 384px !important;
  }

  .vbg-rsp-dropdown-container {
    max-width: 384px !important;
  }
}

@media (max-width: 1024px) {

  .dropdown-menu li.chosen>a {
    color: #000 !important;
  }

  .dropdown-menu li.chosen {
    border-left: none !important;
  }

  .dropdown-menu>li>a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    letter-spacing: 0;
    line-height: 16px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .vbg-sp-btn-continue {
    display: block;
  }

  .vbg-ff-btn-continue {
    margin-bottom: 12px;
    }
  }
  
</style>
  
