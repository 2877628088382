import Vue from 'vue'
import VueRouter from 'vue-router'

import ContainerComponent from '@/profile/components/core/ContainerComponent'
import RootComponent from '@/profile/components/core/RootComponent'
import OverviewComponent from '@/profile/components/postauth/OverviewComponent'
import InterceptComponent from '@/profile/components/preauth/InterceptComponent'
import SetSQAComponent from '@/profile/components/preauth/SetSQAComponent'
import AddOTPDeviceComponent from '@/profile/components/core/AddOTPDeviceComponent'
import OTPSummaryComponent from '@/profile/components/core/OTPSummaryComponent'
import ResetPasswordComponent from '@/profile/components/preauth/ResetPasswordComponent'
import ModifySQAComponent from '@/profile/components/postauth/ModifySQAComponent'
import ModifyPasswordComponent from '@/profile/components/postauth/ModifyPasswordComponent'
import DeleteOTPComponent from '@/profile/components/postauth/DeleteOTPComponent'
import NotFoundComponent from '@/common/components/NotFoundComponent'
import ProcessLoaderComponent from '@/profile/components/core/ProcessLoaderComponent'
import ErrorPage from '@/common/components/ErrorPage'
import BuildInfo from '@/vbgauth/components/BuildInfo'
import SessionExpiredError from '@/profile/components/preauth/SessionExpiredError'
import DeliveryOverview from "@/profile/components/postauth/DeliveryOverview.vue"
import AddDeliveryComponent from "@/profile/components/postauth/AddDeliveryComponent.vue"
import VerifyDeliveryComponent from "@/profile/components/postauth/VerifyDeliveryComponent.vue"

Vue.use(VueRouter)
var baseUrl = '/account/business/'
const profileRouter = new VueRouter({
    base: baseUrl,
    mode: 'history',
    linkActiveClass: 'open active',
    routes: [
      {
        path: '/profile/',
        name: 'ContainerComponent',
        component: ContainerComponent,
        children: [
          {
            path: 'preauth',
            name: 'RootComponent',
            component: RootComponent,
            children: [
            {
              path: 'intercept',
              name: 'InterceptComponent',
              component: InterceptComponent,
            },
            {
              path: 'setsqa',
              name: 'SetSQAComponent',
              component: SetSQAComponent,
            },
            {
              path: 'addotpdevice',
              name: 'AddOTPDeviceComponent',
              component: AddOTPDeviceComponent,
            },
            {
              path: 'otpsummary',
              name: 'OTPSummaryComponent',
              component: OTPSummaryComponent,
            },
            {
              path: 'resetpassword',
              name: 'ResetPasswordComponent',
              component: ResetPasswordComponent,
            },
            {
              path: 'redirecting',
              name: 'ProcessLoaderComponent',
              component: ProcessLoaderComponent,
            }
            ]
          }
          ,
          {
            path: 'postauth',
            name: 'RootComponent',
            component: RootComponent,
            children: [
              {
            path: 'overview',
            name: 'OverviewComponent',
            component: OverviewComponent
          },
          {
            path: "deliveryoverview",
            name: "DeliveryOverview",
            component: DeliveryOverview,
          },
          {
            path: 'addotpdevice',
            name: 'AddDeliveryComponent',
            component: AddDeliveryComponent,
          },
          {
            path: 'verifydelivery',
            name: 'VerifyDeliveryComponent',
            component: VerifyDeliveryComponent,
          },
          {
            path: 'otpsummary',
            name: 'OTPSummaryComponent',
            component: OTPSummaryComponent,
          },
          {
            path: 'modifypassword',
            name: 'ModifyPasswordComponent',
            component: ModifyPasswordComponent
          },
          {
            path: 'modifysqa',
            name: 'ModifySQAComponent',
            component: ModifySQAComponent
          },
          {
            
            path: 'deleteOTP',
            name: 'DeleteOTPComponent',
            component: DeleteOTPComponent
          }
        ]
        }
        ]
      },
      {
        path: '/profile/sessionexpired',
        name: 'SessionExpiredError',
        component: SessionExpiredError,
      },
      { path: '*', component: NotFoundComponent },
      { path: '*', component: ErrorPage }
    ]
})
  export default profileRouter
