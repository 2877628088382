<template>
  <div class="wh_banner">
    <div class="banner_vbg">
      <div class="banner_content">
        <div class="banner_width">
          <div class="banner_text">
            <div class="banner_heading">Off to a great start! Keep going.</div>
            <div class="task_count">
              {{ progressData.completedTasks }}/{{
                progressData.totalMandatoryTasks
              }}
              tasks completed
            </div>
          </div>
          <div class="progress_container">
            <div
              class="progress_bar"
              :style="{ width: `${progressPercentageBar}%` }"
            ></div>
          </div>
        </div>
        <div class="banner_align_btn">
          <button class="banner_btn" @click="goBack">
            Complete your onboarding
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "WelcomeHubBanner",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBack() {
      window.location.href =
        iamvar_mbt_base_url + "/mbt/secure/index?appName=esm#/welcome";
    },
    ...mapActions("profile", []),
  },
  computed: {
    ...mapGetters("profile", ["errorMsg", "showerrorMsg", "progressData"]),
    progressPercentageBar() {
      return (
        (this.progressData.completedTasks /
          this.progressData.totalMandatoryTasks) *
        100
      );
    },
  },
  watch: {
    progressData: {
      deep: true,
      handler(newVal, oldValue) {
        this.getProgressValue(
          newVal.totalMandatoryTasks,
          newVal.completedTasks
        );
        this.totalMandatoryTasks = newVal.totalMandatoryTasks;
        this.completedTasks = newVal.completedTasks;
      },
    },
  },
};
</script>
<style scoped>
.wh_banner {
  background-color: #f3ede0;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 22px;
}
.banner_vbg {
  display: flex;
  justify-content: space-between;
  max-width: 1272px;
  height: auto;
  width: 100%;
  margin: 0 auto;
}
.banner_heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  font-family: "VzNHGeDS-bold11";
}
.banner_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task_count {
  font-family: "VzNHGeDS-reg11";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #000000;
}
.banner_content {
  height: auto;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
}
.banner_btn {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 700;
  font-family: "VzNHGeTX-bold11";
  padding: 12px 12px;
  color: #000000;
}
.progress_container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}
.progress_bar {
  background-color: #008331;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  height: 10px;
  width: 0;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
  border-radius: 20px;
}
.banner_width {
  width: 80%;
}
@media (max-width: 1200px) {
  .banner_width {
    width: 75%;
  }
}
@media (max-width: 1024px) {
  .banner_content {
    height: auto;
    width: 100%;
    display: flex;
    padding: 0px 16px;
    align-items: center;
    flex-direction: column;
  }
  .wh_banner {
    background-color: #f3ede0;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
  }
  .banner_width {
    width: 100%;
  }
  .banner_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .banner_heading {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
  .task_count {
    margin-bottom: 8px;
  }
  .banner_btn {
    margin-top: 38px;
  }
  .banner_align_btn {
    width: 100%;
  }
}
</style>
