import { render, staticRenderFns } from "./VerifyDeliveryComponent.vue?vue&type=template&id=89f50e42&scoped=true"
import script from "./VerifyDeliveryComponent.vue?vue&type=script&lang=js"
export * from "./VerifyDeliveryComponent.vue?vue&type=script&lang=js"
import style0 from "./VerifyDeliveryComponent.vue?vue&type=style&index=0&id=89f50e42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89f50e42",
  null
  
)

export default component.exports