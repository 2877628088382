var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.submitLoader)?_c('BlockUI',{attrs:{"url":_vm.url}}):(!_vm.submitLoader)?_c('div',[_c('div',{staticClass:"vbg-rsp-title-container"},[_c('h1',{staticClass:"vbg-rsp-title-h1"},[_vm._v(" "+_vm._s(_vm.$t("message.postauth_add_verification_title"))+" ")])]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.postauth_summary_desc)}}),_c('div',{staticClass:"vbg-rsp-form-container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('div',{staticClass:"vbg-rsp-dropdown-container1"},[_c('div',{staticClass:"vbg-rsp-dropdown-container"},[_c('label',{staticClass:"vbg-ff-input-label",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP },attrs:{"for":"otp-dropdown"}},[_vm._v(" "+_vm._s(_vm.$t("message.login_otp_device_dropdown_label"))+" ")]),_c('div',{attrs:{"id":"drpdn"}},[_c('div',{staticClass:"row dropdown dropdown-toggle",class:{
              open: _vm.showDropdown,
              'vbg-rsp-disabled-select': _vm.showVerifyOTP,
            },attrs:{"aria-haspopup":"true","aria-expanded":"false","tabindex":"1"},on:{"click":function($event){!_vm.showVerifyOTP && _vm.toggleDeliverySelectBox()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;!_vm.showVerifyOTP && _vm.toggleDeliverySelectBox()}}},[_c('div',{staticClass:"dropDefaultLabel"},[_c('a',{attrs:{"id":"dropdownMenu1","name":"dropdownMenu1","aria-haspopup":"true","role":"button"}},[_c('span',{staticClass:"padleft10",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP }},[_vm._v(" "+_vm._s(_vm.selectedOtpMethod.label)+" ")])])]),_c('img',{staticClass:"vbg-rsp-dropdown-cheveron",attrs:{"src":_vm.downCaret}}),_c('ul',{staticClass:"dropdown-menu col-xs-12 px-0",attrs:{"aria-labelledby":"dropdownMenu1"}},_vm._l((_vm.otpDeviceList.isSmbUser
                  ? _vm.otpMethodsForSmb
                  : _vm.otpMethods),function(method,index){return _c('li',{key:method.id,class:{
                      chosen: method.id === _vm.selectedOtpMethod.id,
                    },attrs:{"role":"presentation"}},[_c('a',{ref:"selectDeviceList",refInFor:true,staticStyle:{"height":"40px"},attrs:{"role":"menuitem"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectDeliveryMethod(method, 'enter')},function($event){return _vm.deviceListkeyupHandler($event, index)}],"click":function($event){return _vm.selectDeliveryMethod(method, 'click')}}},[_c('span',[_c('span',[_vm._v(_vm._s(method.label))])])])])}),0)])])])]),(_vm.selectedOtpType)?_c('div',[_c('div',{staticClass:"user-otp-input-container"},[_c('label',{staticClass:"vbg-ff-input-label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.selectedOtpType === "email" ? "Email" : "10-digit phone number")+" ")]),_c('div',{staticClass:"vbg-rsp-icon-input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInputText),expression:"userInputText"}],ref:"userInputText",staticClass:"vbg-rsp-input-field",class:[
                {
                  'vbg-rsp-disabled-input': _vm.showVerifyOTP,
                  'vbg-rsp-input-warning': !_vm.isValidText && _vm.userInputText,
                },
              ],attrs:{"type":"text","id":"user-input-val","name":"user-input-val","maxlength":"60","disabled":_vm.showVerifyOTP},domProps:{"value":(_vm.userInputText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();},"input":[function($event){if($event.target.composing)return;_vm.userInputText=$event.target.value},function($event){return _vm.validateUserInput()}]}}),(!_vm.isValidText && _vm.userInputText)?_c('div',{staticClass:"vbg-rsp-warning-icon"},[_c('img',{attrs:{"src":_vm.warningIcon}})]):_vm._e()]),(_vm.selectedOtpType && _vm.userInputText && !_vm.isValidText)?_c('span',{staticClass:"vbg-rsp-warning-msg",attrs:{"id":"currentPassword-help-block"},domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"vbg-sp-btn-continue"},[_c('button',{staticClass:"btn vbg-ff-btn-continue vbg-ff-btn-width140 vbg-rsp-add-btn spacing05",class:{
          'vbg-rsp-disabled-btn':
            _vm.disableDeliveryBtn ||
            _vm.showVerifyOTP,
        },attrs:{"type":"submit","disabled":_vm.disableDeliveryBtn || _vm.showVerifyOTP,"id":"adddeliverymethod_button","name":"adddeliverymethod_button"}},[_vm._v(" Add delivery method ")]),(_vm.otpDeviceList.otpDeviceList.length >= 1)?_c('button',{staticClass:"btn vbg-ff-btn-cancel vbg-ff-btn-width140 vbg-rsp-add-btn spacing05",class:{ 'vbg-rsp-disabled-btn-cancel': _vm.showVerifyOTP },attrs:{"type":"button","disabled":_vm.showVerifyOTP || _vm.smbEmptyCheck,"id":"btn_back","name":"btn_back"},on:{"click":function($event){return _vm.gotoOverview()}}},[_vm._v(" Cancel ")]):_vm._e()])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }