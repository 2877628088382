<template>
  <div>
  <!-- <WelcomeHubBanner v-if="showBanner"/> -->
  <div class="vbg-ff-root-container">
    <div class="vbg-ff-content-container">
      <div class="vbg-rsp-root-container">
        <div>
          <div class="over-view-main-container">
            <div class="breadcrumb-container">
              <a class="btn-breadcrumb-home" @click="redirect()" id="home_breadcrumb" name="home_breadcrumb">Home</a>
              <span class="breadcrumb-icon-caret-right"> / </span>
              <span class="btn-breadcrumb-home" @click="profileRedirect()" id="profile_breadcrumb">My profile &
                setting</span>
              <span class="breadcrumb-icon-caret-right"> / </span>
              <span class="btn-breadcrumb-myprofile">Verification delivery methods</span>
            </div>
            <h1 class="change-del-text">Verification delivery methods</h1>
            <div class="del-sub-text">Have at least 3 delivery methods to make authentication more easy. You can have up
              to 5 total delivery methods.</div>
            <div class="ovrview-msg" v-if="isButtonDisabled">
              <div class="vbg-ff-messagesInfo" v-bind:class="'information'">
                <div class="vbg-ff-msg-container">
                  <div class="messageWrapper">
                    <div class="messageIcon msg-icon-position">
                      <div class="messageImage" :class="'informationMessageImage'"></div>
                    </div>
                    <p class="messageContentUn" v-html="max_devices_limit_msg">
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="devices-table">
              <table class="view-otp-table">
                <thead class="table-head">
                  <tr>
                    <td class="table-heading" style="width: 360px;">Delivery method</td>
                    <td class="table-heading" v-if="otpDeviceList.otpDeviceList.length != 1">Remove</td>
                  </tr>
                </thead>

                <tbody class="table-body">
                  <tr :class="[otpDeviceList.otpDeviceList.length - 1 == name ? 'table-body' : '']"
                    v-for="(value, name) in otpDeviceList.otpDeviceList" :key="name">
                    <td class="table_text" v-if="value.otp_type.toLowerCase() == 'email'"> <div v-if="value.vzCarrier == true" class="secure_text">Recommended</div>Email
                      <span class="device-type-pos">{{
                        value.otp_value }}</span>
                        <div v-if="value.profileMTN" style="display:flex"><img class="warnImg"
                          src="@/common/assets/styles/corp/images/warning12.png" />
                        <div>
                          <div class="profile-desc">Can't be removed because it's linked to your profile.</div><span
                            class="manage-profile" @click="profileRedirect()">Manage my profile</span>
                        </div>
                      </div>
                    </td>
                    <td v-if="value.otp_type.toLowerCase() == 'sms'"><div v-if="value.vzCarrier == true" class="secure_text">Recommended</div>Text <span class="device-type-pos">{{
                      value.otp_value
                    }}</span>
                    <div v-if="value.profileMTN" style="display:flex"><img class="warnImg"
                      src="@/common/assets/styles/corp/images/warning12.png" />
                    <div>
                      <div class="profile-desc">Can't be removed because it's linked to your profile.</div><span
                        class="manage-profile" @click="profileRedirect()">Manage my profile</span>
                    </div>
                  </div>
                  </td>
                    <td v-if="value.otp_type.toLowerCase() == 'ivr'">Call <span class="device-type-pos">{{
                      value.otp_value
                        }}</span><div v-if="value.profileMTN" style="display:flex"><img class="warnImg"
                          src="@/common/assets/styles/corp/images/warning12.png" />
                        <div>
                          <div class="profile-desc">Can't be removed because it's linked to your profile.</div><span
                            class="manage-profile" @click="profileRedirect()">Manage my profile</span>
                        </div>
                      </div></td>
                    <td v-if="value.profileMTN">
                      <a @click="deleteDevice(value)" class="vbg-rsp-device-table-delete-icon trash-diasabled"
                        v-if="otpDeviceList.otpDeviceList.length != 1" id="delete_icon" name="delete_icon"><img
                          class="delete-icon-size" src="@/common/assets/styles/corp/images/trash1.png" /></a>
                    </td>
                    <td v-if="!value.profileMTN">
                      <a @click="deleteDevice(value)" class="vbg-rsp-device-table-delete-icon"
                        v-if="otpDeviceList.otpDeviceList.length != 1" id="delete_icon" name="delete_icon"><img
                          class="delete-icon-size" src="@/common/assets/styles/corp/images/trash.png" /></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mr-32">
              <button type="submit"
                @click="profileRedirect() " class="btn spacing05 add-delivery-btn add-delivery-method-btn"
                id="add_delivery_method_button" name="add_delivery_method_button">
                <span>Go to Home page
                  </span>
              </button>
              <button type="submit" :disabled="isButtonDisabled" class="btn add-delivery-btn add-delivery-cancel spacing05" @click="callStepUP('addotpdevice')"
                id="rsp-otp-device-continue-btn" name="rsp-otp-device-continue-btn">
                Add delivery method
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setPageDetails, eventTracking, errorSet } from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins'
import translations from "@/common/assets/json/messages1.json";
import WelcomeHubBanner from "../../../vbgauth/components/WelcomeHubBanner.vue";

export default {
  name: 'DeliveryOverview',
  components: {
    WelcomeHubBanner
  },
  mixins: [commonMixin],
  // async created() {
  //   setPageDetails('Profile:Overview'); 
  // },
  data() {
    return {
      delSelectedDevice: "",
      deviceToBeDelete: "",
      deviceTypeToBeDelete: "",
      uuid: "",
      modalShown: false,
      targetUrl: "",
      whichRealm: true,
      max_devices_limit_msg: translations.en.message['reset_security_profile_otp_summary_max_devices'],
      quickSettingsUrl: iamvar_quick_settings_url,
    };
  },
  mounted() {
    this.$emit("component-loaded", "ModifySQAComponent")
    if (this.getParameterByName('stepupVerification')) {
      this.$store.commit('profile/setStepupVerified', true)
    } else {

      this.$store.commit('profile/setStepupVerified', false)
    }
    this.getUserProfile(this.getParameterByName("target"))

    if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      let iamvar_appCookie = getCookie('X-VZ-C_APP')
      if (iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    this.changeContextBiocatch('postauth_overview_page')
    this.updateUrlOnMount(window.location.href);
    // this.updateTargetUrl(this.getParameterByName("target"))
  },
  computed: {
    ...mapGetters("profile", [
      'emailAddress',
      'userName',
      'firstName',
      'lastName',
      'loginLastTime',
      'otpDeviceList',
      'sqa',
      'uuID',
      'infoMsg',
      'showinfoMsg',
      'profileRedirectUrl',
      'stepupVerified',
      'logOutGotoUrl',
      'urlOnMount',
      'userType',
      'showBanner'
    ]),
    ...mapGetters('cvsstepup', [
      'userValidationError',
      'currentStep',
      'cannotProceedRecovery',
      'flowType',]),
    isButtonDisabled() {
      if (this.otpDeviceList && this.otpDeviceList.otpDeviceList) {
        return this.otpDeviceList.otpDeviceList.length == 5;
      } else {
        return false
      }

    },
  },
  methods: {
    ...mapActions('cvsstepup', [
      'updateUsernameForStepup',
      'updateFlowType',
      'initializeStepup'
    ]),
    ...mapActions("profile", [
      'getUserProfile',
      'deleteOTPDevice',
      'OttSetUP',
      'resetMessages',
      'updateTargetUrl',
      'updateUrlOnMount',
      "updateProgressDataAction"


    ]),
    redirect: function () {
      let homeurl = this.logOutGotoUrl;
      if(homeurl.startsWith("https:/") || homeurl.startsWith("http:/")) {
        homeurl = homeurl.replace("https:/", "https://").replace("http:/", "http://")
      }
      let sanitizedUrl = this.sanitizeUrl(homeurl);
      window.location.href = sanitizedUrl != null ? homeurl : this.sanitizeUrl(this.urlOnMount);
    },
    deleteDevice(item) {
      this.deviceToBeDelete = item.otp_value;
      this.delSelectedDevice = item.guid;
      this.deviceTypeToBeDelete = item.otp_type.toLowerCase();
      // window.scrollTo(0,0);
      // $("#modal-popup").modal({
      //   backdrop: "static",
      // });
      window.sessionStorage.setItem('deviceToBeDelete', item.otp_value)
      window.sessionStorage.setItem('delSelectedDevice', item.guid)
      window.sessionStorage.setItem('deviceTypeToBeDelete', item.otp_type.toLowerCase())
      window.sessionStorage.setItem('uuid', this.uuID)
      this.callStepUP("deleteOTP")
    },
    callStepUP(attribute) {
      this.resetMessages()
      let payload = {
        attrUpdated: attribute,
        userName: this.userName
      }
      this.OttSetUP(payload)
      sessionStorage.setItem('optSelected', attribute)

    },
    profileRedirect: function () {
      let sanitizedUrl = this.sanitizeUrl(this.quickSettingsUrl);
      window.location.href = sanitizedUrl != null ? this.quickSettingsUrl : this.sanitizeUrl(this.urlOnMount);
    },
    parseUrl() {
      const urlParams = this.getParameterByName("target");
      if(urlParams) {
        const fragment = window.location.hash.substring(1);
        const targetParam = urlParams+'#'+ fragment;
        this.updateTargetUrl(targetParam)
      } else {
        this.updateTargetUrl(null);
      }
    },
  },
  watch: {
    userType: function (newVal) {
      if (newVal) {
        this.parseUrl();
      }
    },
  }


}
</script>

<style scoped>
.over-view-main-container {
  top: -28px;
}

.change-del-text {
  margin-top: 32px;
  margin-bottom: 12px;
  line-height: 36px;
  font-weight: 700;
  color: #000000;
}

.del-sub-text {
  font-family: "VzNHGeDS-reg11", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  margin-bottom: 32px;
}

.devices-table {
  width: 617px;
}

.view-otp-table {
  width: 100%;
}

td,
th {
  border: 0px;
  text-align: left;
  padding: 8px;
}

.view-otp-table .table-head {
  height: 88px;
}

.table-heading {
  font-size: 20px !important;
}

.view-otp-table .table-head {
  border-bottom: 2px solid black !important;
}

.view-otp-table .table-body tr {
  border-bottom: 1px solid #dad8d8 !important;
  height: 80px;
}

.view-otp-table .table-body tr td {
  padding: 24px 10px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #000;
}
.trash-diasabled{
  cursor: not-allowed;
}

.delete-icon-size {
  width: 24px !important;
  height: 24px !important;
}

.btn.vbg-ff-btn-cancel {
  margin-top: 32px;
}
.warnImg {
  Width: 13.34px;
  Height: 11.99px;
  margin-top: 4.01px;
  margin-right: 3.33px;
}

.add-delivery-btn,
.add-delivery-btn:hover {
  width: 206px;
  height: 44px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.5px !important;
  margin-right: 12px;
  padding: 12px 24px;
}

.add-delivery-method-btn {
  color: #fff !important;
  background-color: #000 !important;
}

.add-delivery-cancel {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000000;
}

button span {
  top: 0;
}

table thead {
  border-top: none;
}

.mr-32 {
  margin-top: 32px;
}

.vbg-rsp-device-table-delete-icon {
  margin-left: 15px;
}
.profile-desc {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.manage-profile {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  border-bottom: 1px solid #000000;
  text-decoration: none;
  width: 114px;
  cursor: pointer;
}

@media only screen and (min-width: 280px) and (max-width: 1024px) {
  .devices-table {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .delete-icon-size {
    height: 24px;
    width: 24px;
  }
}
</style>