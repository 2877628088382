<template>
  <div>
    <BlockUI v-if="submitLoader":url="url"></BlockUI>
    <div v-if="showVerifyOTP" class="vbg-rsp-verify-method-container">
      <form @submit.stop.prevent="OnOtpSubmit()">
        <h2 class="vbg-rsp-title-h2">Verify your delivery method</h2>
        <p class="vbg-add-desc">
          Please enter the one-time passcode you received at
          {{ maskedValue }}. <br />Passcode is valid for 10 minutes.
        </p>
        <div class="user-otp-input-container">
          <label class="vbg-ff-input-label" for="onepasscode">
            One-time passcode
          </label>
          <input type="password" class="vbg-rsp-input-field" id="onepasscode" name="onepasscode" v-model="onepasscode"
            maxlength="6" style="display: block" @keypress="onlyNumber" autofocus />
          <span id="currentPassword-help-block" class="vbg-rsp-warning-msg"
            v-if="onepasscode.length !== 0 && onepasscode.length !== 6">
            Please enter only 6-digits Passcode
          </span>
        </div>
        <div class="vbg-sp-btn-continue">
          <button type="submit" class="btn vbg-ff-btn-continue vbg-rsp-btn-width120 btn-verify spacing05"
            :class="{ 'vbg-ff-disabled': onepasscode.length !== 6 }" :disabled="onepasscode.length !== 6"
            id="verify_button" name="verify_button">
            Verify
          </button>
          <button type="button" class="btn vbg-ff-btn-cancel vbg-rsp-btn-width120 btn-verify spacing05"
            @click="cancelVerifyOtp()" id="cancel_button" name="cancel_button">
            Cancel
          </button>
        </div>

        <p class="vz-text-link resend-passcode-link">
          <a class="vbg-rsp-link" href="#" id="resendlink" @click="onSubmit()">
            Resend passcode
          </a>
        </p>
        <p class="vz-text-link cant-receive-passcode-link">
          <a class="vbg-rsp-link" href="#" @click="openModel()" id="launchInfo">
            I can't receive a one-time passcode
          </a>
        </p>
        <div id="modal-popup" class="modal fade">
          <div class="vbg-model-container" role="document">
            <div class="modal-content modal-content-container">
              <div class="modal-container">
                <div class="close-icon-container" tabindex="1">
                  <a data-dismiss="modal">
                    <img src="@/common/assets/styles/corp/images/close_black.png" style="height: 20px; width: 20px" />
                  </a>
                </div>
                <div class="window-content mar-bottom50">
                  <div>
                    <h2 class="martop0 marbottom20 modal-content-title">
                      Can't receive a one time passcode?
                    </h2>
                    <p class="modal-content-description">
                      Please try another delivery method, contact your company
                      administrator, or
                      <a href="https://www.verizon.com/business/support/" class="vbg-ff-focus vbg-support-link"
                        target="_blank" tabindex="2" id="visit_support" name="visit_support">visit our support
                        page.</a>
                    </p>
                  </div>
                </div>

                <div class="modal-btn-container martop100">
                  <button type="button" data-dismiss="modal"
                    class="btn vbg-ff-focus-btn primary_black martop50 vbg-rel-bottom-40 bttn-container  spacing05"
                    tabindex="3" id="close_modal" name="close_modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import $ from "jquery";
import { setPageDetails, eventTracking, errorSet } from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import downCaret from "@/common/assets/styles/corp/images/down-caret_blk.png";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import CheckIcon from "@/common/assets/styles/corp/images/checkmark.png";
import translations from "@/common/assets/json/messages1.json";
export default {
  name: "VerifyDeliveryComponent",
  data() {
    return { 
      onepasscode: "",
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
    };
  },
  components: {

  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Profile:Add OTP device");
  },
  methods: {
    ...mapMutations('profile', ['setloader']),
    ...mapActions("profile", [
      "sendotpToDevice",
      "verifyOtpCode",
      "addOTPDevice",
      "getUserProfile",
      "isTxnValid",
      "getOtpDeviceList",
      "resetMessages",
      "resetShowVerifyOtp",
      "updateTargetUrl",
    ]),

    openModel() {
      $("#modal-popup").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    cancelVerifyOtp() {
      this.resetShowVerifyOtp(false);
      this.resetMessages();
      this.$router.push({ path: 'addotpdevice', query: { target: this.logOutGotoUrl ?? undefined }});
    },

    async onSubmit() {
      try {
        const payload = {
          uuID: this.uuID,
        };
        if (this.getSelectedOtpType == "email") {
          payload.emailAddress = this.getUserInputText;
          payload.otpType = "email";
        } else if (this.getSelectedOtpType == "call") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "ivr";
        } else if (this.getSelectedOtpType == "text") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "sms";
        }

        // submit addotpdevice request
        await this.sendotpToDevice(payload);
      } catch (error) {

      }
    },

    async OnOtpSubmit() {
      this.setloader(true);
      try {
        const payload = {
          uuID: this.uuID,
          otpToken: this.onepasscode,
          emailAddress: "",
          mtn: "",
          countryCode: "1",
        };
        if (this.getSelectedOtpType == "email") {
          payload.emailAddress = this.getUserInputText;
          payload.deliveryMethodSelected = "email";
        } else if (this.getSelectedOtpType == "text") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.deliveryMethodSelected = "sms";
        } else if (this.getSelectedOtpType == "call") {
          payload.mtn = this.getUserInputText.replace(/-/g, "");
          payload.deliveryMethodSelected = "ivr";
        }

        await this.verifyOtpCode(payload);
      } catch (error) {
        this.setloader(false);
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    parseUrl() {
      const urlParams = this.getParameterByName("target");
      if(urlParams) {
        const fragment = window.location.hash.substring(1);
        const targetParam = urlParams+'#'+ fragment;
        this.updateTargetUrl(targetParam)
      } else {
        this.updateTargetUrl(null);
      }
    },
  },
  computed: {
    ...mapGetters("profile", [
      "showVerifyOTP",
      "gotoDevSumry",
      "uuID",
      "submitLoader",
      "otpDeviceList",
      "maskedValue",
      "stepupVerified",
      "logOutGotoUrl",
      'userType',
      'getSelectedOtpType',
      'getUserInputText',
      'deviceType',
    ]),
    currentRouteName() {
      return this.$route.path;
    },
  },
  mounted() {
    // URLSearchParams not supported in IE
    // let params = new URLSearchParams(window.location.search)
    if (this.getParameterByName("stepupVerification")) {
      this.$store.commit("profile/setStepupVerified", true);
    } else {
      this.$store.commit("profile/setStepupVerified", false);
    }
    this.$store.commit("profile/setAttributedUpdated", "addotpdevice");

    if (this.currentRouteName.includes("/profile/postauth")) {
      this.getUserProfile(this.getParameterByName("target"));
      if (iamvar_oneSessionPerLoginEnable !== true) {
        this.isTxnValid(this.getParameterByName("target"));
      }
    } else {
      this.getOtpDeviceList(this.uuID);
    }
      // this.updateTargetUrl(this.getParameterByName("target"));
    $(document).ready(function () {
      $("#Info").popover({ trigger: "hover focus" });
    });
    if (this.$route.path.includes('postauth')) {
      this.changeContextBiocatch('postauth_addotpdevice_page')
    } else {
      this.changeContextBiocatch('preauth_addotpdevice_page')
    }
    sessionStorage.removeItem('optSelected')
  },
  watch: {
    gotoDevSumry: function (newVal) {
      if (newVal === "preauth") {
        this.$router.push({ path: "otpsummary" });
      } else {
        this.$router.push({
          path: "overview",
          query: { target: this.logOutGotoUrl ?? undefined }
        });
      }
    },
    $route(to, from) {
      this.resetMessages();
    },
    userType: function (newVal) {
      if (newVal) {
        this.parseUrl();
      }
    },
    showVerifyOTP: function (new_val) {
      // if (!new_val) this.setDeliveryComponentName("NewAddOTPComponent");
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box !important;
}

.resend-passcode-link {
  margin-top: 18px !important;
}

.cant-receive-passcode-link {
  margin-top: 24px !important;
}

label {
  display: block !important;
}

.user-otp-input-container {
  margin-top: 32px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: grey;
}

.vz-text-link {
  color: #000;
  margin-bottom: 18px !important;
  text-decoration: none !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.vbg-rsp-input-field {
  margin-bottom: 8px !important;
  border-radius: 4px !important
}

.btn-verify {
  height: 44px !important;
  line-height: 20px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}

.vbg-rsp-title-h2 {
  color: #000 !important;
  line-height: 24px;
}

.btn:active,
.btn:hover,
.btn:focus {
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}

.vbg-rel-bottom-40 {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}

.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}

.vbg-sp-btn-continue {
  margin-top: 32px;
  display: block;
}

.vbg-add-desc {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

@media (min-width: 350px) and (max-width: 1024px) {

  .modal-content {
    text-align: justify;
  }
}

@media (max-width: 1024px) {
  .vz-text-link {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  .vbg-ff-btn-continue {
    margin-bottom: 12px;
  }
}

/* CSS only for desktop */
@media (min-width: 1025px) {
  .vbg-rsp-input-field {
    width: 278px !important;
  }

  .btn-verify {
    width: 94px;
  }
}

/* modal css */
.modal-button {
  margin-top: 37.34px;
  height: 44px;
  width: 188px;
}

.modal-content-container {
  position: relative;
  width: 640px;
  height: 431px !important;
  margin: 0 auto;
  padding: 48px !important;
}

.modal-content-title {
  height: auto;
  width: 100%;
  font-size: 32px;
  line-height: 32px;
  margin-top: 0px !important;
  text-align: left !important;
}

.modal-content-description {
  margin-top: 24px;
  height: 40px;
  width: 100%;
  font-size: 16px !important;
  letter-spacing: 0.5px !important;
  line-height: 20px;
  text-align: left !important;
}

.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px;
}

.close-icon-container:focus {
  outline: 1px dashed #000;
  outline-offset: -12;
}

.bttn-container {
  height: 44px;
  width: 120px;
  border-radius: 22px;
  background-color: #000000;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 12px !important;
  text-align: center;
}

.modal-open {
  padding-right: unset !important;
}

.window-content {
  height: 96px;
  width: 90%;
}

.vbg-rsp-root-container {
  padding: 32px 20px !important;
}

.vbg-rsp-link {
  color: #000 !important;
}

.vbg-rsp-verify-method-container {
  margin-top: 0px !important;
}

@media (max-width: 640px) {
  .modal-content-container {
    width: 100%;
    height: 100% !important;
    padding: 0px !important;
  }

  .modal-container {
    height: 360px;
    padding: 69px 0px 0px 16px;
  }

  .modal-content-title {
    height: 58px !important;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
  }

  .modal-content-description {
    margin-top: 24px;
  }

  .modal-loader {
    margin-top: 62px;
  }

  .modal-btn-container {
    padding-right: 16px;
  }

  .close-icon-container {
    height: 48px;
    width: 48px;
    position: relative;
    float: right;
  }

  .close-icon-container {
    top: -12px;
  }

  .bttn-container {
    width: 100%;
  }

  .window-content {
    height: 142px;
    width: 90% !important;
  }
}

.vbg-support-link {
  text-decoration: none !important;
  cursor: pointer !important;
  color: #000 !important;
  border-bottom: 1px solid #000;
}

.vbg-model-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.vbg-ff-focus:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 1px !important;
}

.vbg-ff-focus-btn:focus {
  outline: 1px dashed #000 !important;
  outline-offset: 2px !important;
}

@media (max-width: 280px) {
  .modal-content-description {
    max-width: 260px;
  }

  .modal-content-title {
    height: auto !important;
  }

  .mar-bottom50 {
    margin-bottom: 60px;
  }
}

/* button align on popup desktop/tablet */
@media (min-width: 641px) {
  .vbg-rel-bottom-40 {
    position: absolute;
    bottom: 40px !important;
  }
}
</style>